@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*whatsapp*/

#wa_toggle {
	display:none;
}

label[for="wa_toggle"] {
	position:fixed;
	bottom:2.0rem;
	right:2.0rem;
	width:3.5rem;
	height:3.5rem;
	background: #189893 url("../img/button-whats/icon_whatsapp.png") no-repeat center center / 100%; 
	border-radius:50%;
	cursor:pointer;
	transition:all .2s ease-in-out;
	animation:heartBeat 6s ease-in-out 3s infinite; 
	z-index:999
}

#wa_popup {
	position:fixed;
	z-index:100000;
	bottom:5.5rem;
	right:2.5rem;
	max-width:300px;
	margin-bottom:1rem;
	background:#fff;
	border-radius:10px;
	text-align:left;
	font-size:.9em;overflow:hidden;
	visibility:hidden;
	opacity:0;
	transform:translateX(1.5rem);
	transition:all .2s ease-in-out;
	box-shadow: 0 0 5px rgba(0,0,0,.25);
}

.wa_head{padding:20px;background-color:#189893;color:#fff;}
.wa_title{margin-bottom:5px;font-size:1.35em; font-family: 'Poppins', sans-serif !important; font-weight:700}
.wa_subtitle{font-size:1.0em; font-family: 'Poppins', sans-serif !important; font-weight:300}
.wa_content{padding:20px;}
.wa_content_infos{margin:0 0 20px;line-height:1.75em;font-size:.95em;color:#333}
.wa_list{margin:0;padding:0;list-style:none;}
.wa_list li a{text-decoration:none}
.wa_avatar{width:40px;height:40px;padding:5px;margin-right:10px;}
.wa_text div{line-height:1.55em;}
.wa_name{font-size:1.0em; color:#333; font-family: 'Poppins', sans-serif !important; font-weight:400}
.wa_role{font-size:1.2em; color:#333; font-family: 'Poppins', sans-serif !important; font-weight:700}
.wa_role_2{font-size:1.0em; color:#333; font-family: 'Poppins', sans-serif !important; font-weight:700}
.wa_hidden{display:none !important}

.wa_content_email a{display:flex;align-items:center;color:#}
.wa_content_email img{padding:10px;background:#f8f8f8;border-radius:50%;}
#wa_toggle:checked ~ label[for="wa_toggle"]{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-96 0 512 512'%3E%3Cpath fill='%23ffffff' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z'%3E%3C/path%3E%3C/svg%3E");transform: rotateZ(-360deg);animation:none}

/* WHATSAPP */

.wa_contact{position:relative;margin:5px 0;}
.wa_contact a{display:flex;align-items:center;padding:7px;background:#f8f8f8;border-left:3px solid rgb(88,186,99);border-radius:5px;color:#000;overflow:hidden;transition:all .2s ease-in-out;}
.wa_contact a:hover{background-color:rgba(88,186,99,.25);border-right:25px solid rgb(88,186,99);}
.wa_contact a:after{content:'';position:absolute;top:0;right:-25px;bottom:0;background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'%3E%3C/path%3E%3C/svg%3E") no-repeat center center / 50%;transition:right .2s ease-in-out;}
.wa_contact a:hover:after{right:0;width:25px;}
.wa_contact img{display:block;width:100%;height:auto;}
.wa_contact a:hover .wa_avatar{background-color:#f8f8f8;border-radius:50%;}
#wa_toggle:checked ~ #wa_popup{visibility:visible;opacity:1;transform:translateX(0);}
#wa_toggle:checked ~ #wa_popup .wa_contact:nth-of-type(1){animation:.3s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .wa_contact:nth-of-type(2){animation:.6s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .wa_contact:nth-of-type(3){animation:.9s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .wa_contact:nth-of-type(4){animation:1.2s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .wa_contact:nth-of-type(5){animation:1.5s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .wa_contact:nth-of-type(6){animation:1.8s fadeInRightBig;}

/* EMAIL */

.email_contact {
	position:relative;
	margin:5px 0;
}
.email_contact a {
	display:flex;
	align-items:center;
	padding:7px;
	background:#f8f8f8;
	border-left:3px solid rgb(0,153,204);
	border-radius:5px;
	color:#000;
	overflow:hidden;
	transition:all .2s ease-in-out;
}

.email_contact a:hover {
	background-color:rgba(0,153,204,.25);
	border-right:25px solid rgb(0,153,204);
}

.email_contact a:after {
	content:'';
	position:absolute;
	top:0;
	right:-25px;
	bottom:0;
	background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'%3E%3C/path%3E%3C/svg%3E") no-repeat center center / 50%;
	transition:right .2s ease-in-out;
}

.email_contact a:hover:after {
	right:0;
	width:25px;
}
.email_contact img {
	display:block;
	width:100%;
	height:auto;
}
.email_contact a:hover .wa_avatar {
	background-color:#f8f8f8;
	border-radius:50%;
}

#wa_toggle:checked ~ #wa_popup .email_contact:nth-of-type(1){animation:.3s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .email_contact:nth-of-type(2){animation:.6s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .email_contact:nth-of-type(3){animation:.9s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .email_contact:nth-of-type(4){animation:1.2s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .email_contact:nth-of-type(5){animation:1.5s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .email_contact:nth-of-type(6){animation:1.8s fadeInRightBig;}

/* TELEFONE */

.phone_contact {
	position:relative;margin:5px 0;
}

.phone_contact a {
	display:flex;align-items:center;
	padding:7px;
	background:#f8f8f8;
	border-left:3px solid rgb(255,122,34);
	border-radius:5px;
	color:#000;	
	overflow:hidden;
	transition:all .2s ease-in-out;
}

.phone_contact a:hover {
	background-color:rgba(255,122,34,.25);
	border-right:25px solid rgb(255,122,34);
}

.phone_contact a:after {
	content:'';
	position:absolute;
	top:0;
	right:-25px;
	bottom:0;
	background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'%3E%3C/path%3E%3C/svg%3E") no-repeat center center / 50%;
	transition:right .2s ease-in-out;
}

.phone_contact a:hover:after {
	right:0;
	width:25px;
}

.phone_contact img {
	display:block;
	width:100%;
	height:auto;
}

.phone_contact a:hover .wa_avatar {
	background-color:#f8f8f8;
	border-radius:50%;
}

#wa_toggle:checked ~ #wa_popup .phone_contact:nth-of-type(1){animation:.3s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .phone_contact:nth-of-type(2){animation:.6s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .phone_contact:nth-of-type(3){animation:.9s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .phone_contact:nth-of-type(4){animation:1.2s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .phone_contact:nth-of-type(5){animation:1.5s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .phone_contact:nth-of-type(6){animation:1.8s fadeInRightBig;}

/* CHAT */

.chat_contact {
	position:relative;
	margin:5px 0;}
.chat_contact a {
	display:flex;
	align-items:center;
	padding:7px;
	background:#f8f8f8;
	border-left:3px solid rgb(183,0,60);
	border-radius:5px;
	color:#000;
	overflow:hidden;
	transition:all .2s ease-in-out;
}

.chat_contact a:hover {
	background-color:rgba(183,0,60,.25);
	border-right:25px solid rgb(183,0,60);
}

.chat_contact a:after {
	content:'';
	position:absolute;
	top:0;
	right:-25px;
	bottom:0;
	background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'%3E%3C/path%3E%3C/svg%3E") no-repeat center center / 50%;
	transition:right .2s ease-in-out;
}

.chat_contact a:hover:after {
	right:0;
	width:25px;
}

.chat_contact img {
	display:block;
	width:100%;
	height:auto;
}
.chat_contact a:hover .wa_avatar {
	background-color:#f8f8f8;
	border-radius:50%;
}
#wa_toggle:checked ~ #wa_popup .chat_contact:nth-of-type(1){animation:.3s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .chat_contact:nth-of-type(2){animation:.6s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .chat_contact:nth-of-type(3){animation:.9s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .chat_contact:nth-of-type(4){animation:1.2s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .chat_contact:nth-of-type(5){animation:1.5s fadeInRightBig;}
#wa_toggle:checked ~ #wa_popup .chat_contact:nth-of-type(6){animation:1.8s fadeInRightBig;}


@keyframes fadeInRightBig {from{opacity:0;transform:translateX(150px);}to{opacity:1;transform:translateX(0);}}
@keyframes heartBeat{0%{transform:scale(1)} 3%{transform:scale(1.3)} 6%{transform:scale(1)} 13%{transform:scale(1.3)} 16%{transform:scale(1)}}