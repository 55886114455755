body {
  color: #717477;
  font-family: "Montserrat", sans-serif;
  line-height: 1.8;
  font-size: 16px;
}

/* Default CSS Start */
img {
  max-width: 100%;
}

a {
  -webkit-transition: .5s;
  transition: .5s;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

button {
  outline: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  background-color: transparent;
}

.form-control:focus {
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  color: #333333;
}

h3 {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  color: #0f2137;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

.theme-button {
  z-index: 1;
  position: relative;
}

.theme-button .default-btn {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  background: -webkit-gradient(linear, left top, right top, from(#189893), to(#189893));
  background: linear-gradient(90deg, #189893 0%, #189893 100%);
  padding: 16px 40px;
  position: relative;
  display: inline-block;
}

.theme-button .default-btn::before {
  position: absolute;
  content: '';
  border: 1px solid #189893;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  z-index: -1;
  -webkit-transition: .5s;
  transition: .5s;
}

.theme-button .default-btn:hover::before {
  top: -10px;
  right: -10px;
}

.section-title {
  margin: 0 auto 50px;
  max-width: 600px;
}

.section-title span {
  font-size: 16px;
  color: #886a03;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.section-title h2 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 15px;
}

.buy-now-btn {
  right: 20px;
  z-index: 99;
  top: 50%;
  position: fixed;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: #FFFFFF;
  background-color: #82b440;
  padding: 10px 20px 10px 42px;
  -webkit-box-shadow: 0 1px 20px 1px #82b440;
          box-shadow: 0 1px 20px 1px #82b440;
  font-size: 13px;
  font-weight: 600;
}

.buy-now-btn img {
  top: 50%;
  left: 20px;
  width: 15px;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.buy-now-btn:hover {
  background-color: #189893;
  color: #FFFFFF;
}