/*================================================
Default CSS
=================================================*/
$main-color:#189893;
$body-color: #ffffff;
$heading-color: #ffffff;
// Switch css
.switch-box {
    position: fixed;
    z-index: 1;
    left: 3%;
    bottom: 63px;

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #4d4949;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 30px;
            width: 30px;
            left: 2.5px;
            bottom: 4px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            transition: 0.4s;
            box-shadow: 0 0px 15px #2020203d;
            background: url(../../assets/img/night.png);
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    input:checked + .slider {
        background-color: #cbc1c1;
    }
    input:focus + .slider {
        background-color: #4d4949;
    }
    input:checked + .slider:before {
        transform: translateX(24px);
        background: url(../../assets/img/sunny.png);
        background-repeat: no-repeat;
        background-position: center;
    }
    .slider {
        &.round {
            border-radius: 50px;

            &:before {
                border-radius: 50%;
            } 
        }
    }
}
// Dark Style CSS
.theme-dark {
    body {
        background-color: #000000;
        color: #f1f1f1;
    }
    p {
        color: #f1f1f1 !important;
        
        a {
            color: #f1f1f1 !important;

            &:hover {
                color: $main-color !important;
            }
        }
    }
    a {
        color: $body-color;
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        color: $heading-color !important;
        a {
            color: $heading-color !important;

            &:hover {
                color: $main-color !important;
            }
        }
    }
    //Header
    .header-area {
        background: #0e0e0e;
        .header-left-text {
            ul {
                li {
                    color: $body-color;
                    a {
                        color: $body-color;
                    }
                }
            }
        }
    }
    //Navbar
    .navbar-area {
        .navbar-brand {
            .white-logo {
                display: block;
            }
            .main-logo {
                display: none;
            }
        }
        &.is-sticky {
            background-color: #000000;
        }
    }
    .main-nav {
        background-color: #000000;
        border-bottom: 2px solid #0e0e0e;
        .navbar {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: $body-color;
                    }
                    .dropdown-menu {
                        background: #0e0e0e;
                        
                        .nav-item {
                            .nav-link {
                                color: $body-color;
                                border-bottom: 1px dashed #494848;
                            }
                            .dropdown-menu {
                                .nav-item {
                                    .nav-link {
                                        color: $body-color;
                                        border-bottom: 1px dashed #494848;
                                    }
                                    .dropdown-menu {
                                        .nav-item {
                                            .nav-link {
                                                color: $body-color;
                                                border-bottom: 1px dashed #494848;
                                            }
                                            .dropdown-menu {
                                                .nav-item {
                                                    .nav-link {
                                                        color: $body-color;
                                                        border-bottom: 1px dashed #494848;
                                                    }
                                                    .dropdown-menu {
                                                        .nav-item {
                                                            .nav-link {
                                                                color: $body-color;
                                                                border-bottom: 1px dashed #494848;
                                                            }
                                                            .dropdown-menu {
                                                                .nav-item {
                                                                    .nav-link {
                                                                        color: $body-color;
                                                                        border-bottom: 1px dashed #494848;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                           
                        }
                    }
                }
            }
            .others-options {
                ul {
                    li {
                        .sign-in {
                            color: $body-color;
                        }
                    }
                }
            }
        }
    }
    .navbar-style-two {
        .main-nav {
            .navbar {
                .navbar-nav {
                    .nav-item {
                        .nav-link {
                            color: $body-color;
                            &:hover, &.active, &:focus {
                                color: $main-color !important;
                            }
                        }
                    }
                }
            }
        }
    }

    //Modal
    .modal-header {
        background-color: #000000;
    }
    .right-modal {
        .modal {
            .modal-body {
                background: #0e0e0e;
                .modal-link {
                    h3 {
                        a {
                            background: #000000;
                        }
                    }
                }
            }
        }
    }

    //Banner
    .main-banner {
        .banner-text {
            .theme-button {
                .video-btn {
                    color: $body-color;
                }
            }
        }
    }
    .banner-style-two {
        background: #1c1c1c;
    }

    //Video Section
    .video-section {
        background: #0e0e0e;
    }

    //Theory
    .theory-section {
        .theory-area {
            background: #2c2c2c;
            .theory-text {
                .signature-box {
                    span {
                        color: $body-color;
                    }
                }
            }
        }
    }

    //About
    .about-section {
        background: #000000;
        &.about-style-two {
            .about-text {
                background: #0e0e0e;
            }
        }
        .about-text {
            .about-us {
                i {
                    color: $body-color;
                }
            }
        }
    }

    //Page Title
    .about-title {
        background: #1c1c1c;
    }
    .service-title {
        background: #1c1c1c;
    }
    .portfolio-title {
        background: #1c1c1c;
    }
    .pricing-title {
        background: #1c1c1c;
    }
    .error-title {
        background: #1c1c1c;
    }
    .faq-title {
        background: #1c1c1c;
    }
    .team-title {
        background: #1c1c1c;
    }
    .privacy-title {
        background: #1c1c1c;
    }
    .blog-title {
        background: #1c1c1c;
    }
    .contact-title {
        background: #1c1c1c;
    }

    //Features
    .features-section {
        .features-card {
            background: #0e0e0e;
        }
    }

    //Services
    .service-section {
        .service-text {
            background: #0e0e0e;
            ul {
                li {
                    color: $body-color;
                    background: #000000;
                }
            }
        }
    }
    .service-style-three {
        .service-card {
            background-color: #0e0e0e;
        }
    }
    .blog-search {
        background-color: #0e0e0e;
        form {
            input {
                background-color: #000000;
                color: $body-color;
                &::placeholder {
                    color: $body-color;
                }
            }
        }
    }
    .service-category {
        background-color: #0e0e0e;
        ul {
            li {
                a {
                    color: $body-color;
                }
            }
        }
    }

    //Privacy
    .privacy-section {
        ul {
            li {
                color: $body-color;
            }
        }
    }

    //Faq
    .faq-area {
        .expanded {
            background-color: #0e0e0e;
        }
    }

    //Team
    .team-section .team-card .team-text {
        background: #0e0e0e;
    }

    //Testimonial
    .testimonial-section {
        background: #0e0e0e;
        .testimonial-slider {
            &::before {
                background: #000000;
            }
        }
    }

    //Progress
    .progress-section {
        .progress-text {
            background: #0e0e0e;
        }
    }

    //Pricing
    .pricing-section {
        .pricing-card {
            background: #0e0e0e;
            .pricing-feature {
                ul {
                    li {
                        color: $body-color;
                    }
                }
            }
            &::before {
                background: #262626;
            }
            &.price-card-two {
                &::after {
                    border-top: 100px solid #262626;
                }
            }
        }
    }

    //Blog
    .blog-section {
        .blog-card {
            background: #0e0e0e;
            .blog-text {
                .post-info {
                    .blog-btn {
                        color: $body-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
        }
    }
    .blog-details-area {
        .recent-blog {
            background-color: #0e0e0e;
        }
        .blog-category {
            background-color: #0e0e0e;
            ul {
                li {
                    a {
                        color: $body-color;
                    }
                }
            }
        }
        .tags {
            background-color: #0e0e0e;
            a {
                background-color: #000000;
                color: $body-color;
                &:hover {
                    background-color: $main-color;
                    color: $body-color;
                }
            }
        }
        .blog-description {
            .blog-nav {
                background: #0e0e0e;
                .prev-btn, .next-btn {
                    a {
                        color: $body-color;
                        background: #000000;
                        &:hover {
                            background-color: $main-color;
                            color: $body-color;
                        }
                    }
                }
            }
            .blog-comment {
                background-color: #0e0e0e;
                .form-group {
                    .form-control {
                        background-color: #000000;
                        color: $body-color;
                        &::placeholder {
                            color: $body-color;
                        }
                    }
                    .message-field {
                        background-color: #000000;
                        color: $body-color;
                        &::placeholder {
                            color: $body-color;
                        }
                    }
                }
            }
        }
    }
    
    

    //Contact
    .contact-section {
        .contact-form {
            background: #0e0e0e;
            .form-group {
                .form-control {
                    background-color: #000000;
                    color: $body-color;
                    &::placeholder {
                        color: $body-color;
                    }
                }
                .message-field {
                    background-color: #000000;
                    color: $body-color;
                    &::placeholder {
                        color: $body-color;
                    }
                }
            }
        }
    }
    .contact-area {
        .contact-box {
            background: #0e0e0e;
            .contact-card {
                background-color: #000000;
                ul {
                    li {
                        color: $body-color;
                        a {
                            color: $body-color;
                        }
                    }
                }
            }
        }
    }

    //NewsLetter
    .newsletter-section {
        .newsletter-area {
            background: #151514;
            .newsletter-form {
                .form-control {
                    background: #000000 !important;
                }
            }
        }
    }
    
    //Footer
    .footer-area {
        background: #313131;
    }
    




    @media only screen and (max-width: 991px) {
        .mean-container {
            .mean-bar {
                background-color: #000000;
                padding: 0;
            }
            a {
                &.meanmenu-reveal {
                    span {
                        background-color: #ffffff;
                    }
                }
            }
            .mean-nav {
                ul {
                    li {
                        background: #000000;
                        a {
                            color: $body-color;
                        }
                    }
                }
            }
        }
        .mobile-nav {
            .logo {
                .main-logo {
                    display: none;
                }
                .white-logo {
                    display: block;
                }
            }
        }
    }
}